import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiClient } from '../../../../../api/apiClient';
import { ToastContent } from '../../../../../components/ToastContent';
import { useScannerInput } from '../../../hooks/use-scanner-input';
import styles from './styles.module.css';

export const ActivateLabelPage = ({ modelData, componentsData, updateComponentsData }: { modelData: any; componentsData: any; updateComponentsData: (data: any) => void }) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<any>();
  const [availableLabels, setAvailableLabels] = useState<any[]>([]);
  const [value, setValue] = useState(''/*'0007wm5q'*/);
  const [errorMessage, setErrorMessage] = useState('');

  const loadLabels = async () => {
    const res = await apiClient.get(`/component/getActivation/${modelData.id}`);
    setAvailableLabels(res.data.filter((pm: any) => pm?.type !== 'Board'));
  };

  useEffect(() => {
    loadLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      await apiClient.post(`/q-rcode/validateActivation/${value}`);
      toast(<ToastContent title='Recently added' message={`Label is successfully added`} />);
      const updatedItems = componentsData?.items || [];
      updatedItems.push({ ...selectedLabel, serialNumber: value, type: 'Label' });
      updateComponentsData({ items: updatedItems, name: `${updatedItems.length} items` });
      navigate(-1);
    } catch (e) {
      setValue('');
      setErrorMessage((e as any)?.response?.data.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const processCustomerInput = (input: string) => {
    if (input.startsWith('$')) return;

    const board = availableLabels.find(c => c.name?.startsWith(input));
    setSelectedLabel(board);
  };

  useScannerInput(processCustomerInput);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  const supportedPartModels = modelData?.supportedPartModels ?? [];
  const supportedLabelsAmount = supportedPartModels.filter((pm: any) => pm.type === 'Label')?.length ?? 0;
  const connectedLabelsAmount = (modelData?.items ?? []).filter((i: any) => i.type === 'Label')?.length ?? 0;

  const labelNumber = connectedLabelsAmount + 1;

  if (!selectedLabel) {
    return (
      <div className={styles.container}>
        <div className={styles.headTitle}>Label {labelNumber} of {supportedLabelsAmount}</div>

        <div className={styles.content}>
          <div className={styles.title}>Select label</div>
          <div className={styles.labels}>
            {availableLabels.map(availableLabel => (
              <div key={availableLabel.id} className={styles.label} onClick={() => {
                setSelectedLabel(availableLabel);
              }}>
                <img src={availableLabel.qr} alt={availableLabel.name} />
                <p>{availableLabel.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headTitle}>Label {labelNumber} of {supportedLabelsAmount}</div>
      <div className={styles.content}>
        <div className={styles.title}>Scan QR Code Label</div>
        <div className={styles.description}>Scan QR code label or manually enter QR code label ID</div>
        <input
          className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
          onKeyDown={onKeyDown}
          value={value}
          disabled={isSubmitting}
          onChange={onChange}
          placeholder='Enter QR code label ID'
          key={errorMessage}
          autoFocus
        />
        <div className={styles.error}>
          {errorMessage}
        </div>
      </div>
    </div>
  );
};
