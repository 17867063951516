import { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import styles from './styles.module.css';

interface IInputProps {
  label?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute;
  pattern?: string;
  placeholder?: string;
  errorMessage?: string;
}

export const Input = ({ label, value, onChange, type, pattern, placeholder = '', errorMessage }: IInputProps) => {
  const showSmallLabel = !label && placeholder;

  return (
    <div className={styles.inputWrapper}>
      {label && <p className={styles.label}>{label}</p>}
      <input className={styles.input} value={value} onChange={onChange} placeholder={placeholder} type={type} pattern={pattern} />
      {showSmallLabel && <p className={styles.smallLabel}>{showSmallLabel}</p>}
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};
