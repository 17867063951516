import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiClient } from '../../../../../api/apiClient';
import { ToastContent } from '../../../../../components/ToastContent';
import { useScannerInput } from '../../../hooks/use-scanner-input';
import styles from './styles.module.css';

export const ActivateBoardPage = ({ modelData, componentsData, updateComponentsData }: { modelData: any; componentsData: any; updateComponentsData: (data: any) => void }) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState<any>();
  const [availableBoards, setAvailableBoards] = useState<any[]>([]);
  const [value, setValue] = useState(''/*'12121280011111'*/);
  const [errorMessage, setErrorMessage] = useState('');

  const loadBoards = async () => {
    const res = await apiClient.get(`/component/getActivation/${modelData.id}`);
    setAvailableBoards(res.data.filter((pm: any) => pm?.type === 'Board'));
  };

  useEffect(() => {
    loadBoards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      await apiClient.post(`/component-asset/validateActivation/${value}`);
      toast(<ToastContent title='Recently added' message={`Board is successfully added`} />);
      const updatedItems = componentsData?.items || [];
      updatedItems.push({ ...selectedBoard, serialNumber: value, type: 'Board' });
      updateComponentsData({ items: updatedItems, name: `${updatedItems.length} items` });
      navigate(-1);
    } catch (e) {
      setValue('');
      setErrorMessage((e as any)?.response?.data.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const processCustomerInput = (input: string) => {
    if (input.startsWith('$')) return;

    const board = availableBoards.find(c => c.name?.startsWith(input));
    setSelectedBoard(board);
  };

  useScannerInput(processCustomerInput);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  const supportedPartModels = modelData?.supportedPartModels ?? [];
  const supportedBoardsAmount = supportedPartModels.filter((pm: any) => pm.type === 'Board')?.length ?? 0;
  const connectedBoardsAmount = (modelData?.items ?? []).filter((i: any) => i.type === 'Board')?.length ?? 0;

  const boardNumber = connectedBoardsAmount + 1;

  if (!selectedBoard) {
    return (
      <div className={styles.container}>
        <div className={styles.headTitle}>Board {boardNumber} of {supportedBoardsAmount}</div>

        <div className={styles.content}>
          <div className={styles.title}>Select board</div>
          <div className={styles.boards}>
            {availableBoards.map(availableBoard => (
              <div key={availableBoard.id} className={styles.board} onClick={() => {
                setSelectedBoard(availableBoard);
              }}>
                <img src={availableBoard.qr} alt={availableBoard.name} />
                <p>{availableBoard.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headTitle}>Board {boardNumber} of {supportedBoardsAmount}</div>
      <div className={styles.description}>{selectedBoard?.name}</div>

      <div className={styles.content}>
        <div className={styles.title}>Scan Serial Number</div>
        <div className={styles.description}>Scan model or manually enter board serial number.</div>
        <input
          className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
          onKeyDown={onKeyDown}
          value={value}
          disabled={isSubmitting}
          onChange={onChange}
          placeholder='Enter board serial number'
          key={errorMessage}
          autoFocus
        />
        <div className={styles.error}>
          {errorMessage}
        </div>
      </div>
    </div>
  );
};
