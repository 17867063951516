import { useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActivateBoardPage } from './board';
import { ActivateLabelPage } from './label';
import { CustomerStep } from './steps/CustomerStep';
import { ModelStep } from './steps/ModelStep';
import { SerialStep } from './steps/SerialStep';
import { ComponentsStep } from './steps/ComponentsStep';
import { ReviewStep } from './steps/ReviewStep';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { MAFooter } from '../../components/MAFooter';
import { ToastContent } from '../../../../components/ToastContent';
import { apiClient } from '../../../../api/apiClient';
import styles from './styles.module.css';

const STEPS = [
  { id: 'modelNumber', name: 'Model', title: 'Scan Model', description: 'Scan model or manually enter model number.', Component: ModelStep },
  { id: 'customer', name: 'Customer', title: 'Scan Customer', description: 'Please select your customer below.', Component: CustomerStep },
  { id: 'serialNumber', name: 'Serial Number', title: 'Scan Serial Number', description: 'Scan or manually enter serial number.', Component: SerialStep },
  { id: 'components', name: 'Components', title: 'Components', Component: ComponentsStep },
  { id: 'review', name: 'Review & Submit', title: 'Review & Submit', Component: ReviewStep },
];

export const ActivatePage = () => {
  const { pathname, state } = useLocation();
  const initialStepsData = state?.initialStepsData ?? {};
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ step: 'modelNumber' });
  const currentStepId = searchParams.get('step') ?? 'modelNumber';

  const setCurrentStepId = (stepId: string, isReplace = false) => {
    setSearchParams({ step: stepId }, { replace: isReplace });
  };

  const [stepsData, setStepsData] = useState<Record<string, string>>(initialStepsData);

  const currentStepIndex = STEPS.findIndex(
    step => step.id === currentStepId,
  );

  const currentStepData = STEPS[currentStepIndex ?? 0];
  const { title, description, Component } = currentStepData;

  const goToPrevStep = () => {
    if (currentStepIndex > 0 || pathname.endsWith('/board') || pathname.endsWith('/label')) {
      navigate(-1);
    } else {
      navigate('/manufacturing-activation');
    }
    if (currentStepIndex === 3) {
      // @ts-ignore
      setStepsData(prevData => ({ ...prevData, components: {} }));
    }
  };

  const goToNextStep = async (data?: any, isReplace = false) => {
    if (data) {
      setStepsData(prevData => ({ ...prevData, ...data }));
    }
    if (currentStepIndex === STEPS.length - 1) {
      try {
        await apiClient.post('/asset/activate', {
          serialNumber: stepsData.serialNumber,
          boards: ((stepsData.components as any).items ?? []).filter((i: any) => i.type === 'Board')?.map((board: any) => ({
            id: board.id,
            sn: board.serialNumber,
          })),
          qrLabels: ((stepsData.components as any).items ?? []).filter((i: any) => i.type === 'Label').map((label: any) => label.serialNumber),
        });
        toast(<ToastContent title='Successfull activation' />);
        navigate('/manufacturing-activation');
      } catch (e) {
        toast(<ToastContent title='Activation failed' message='Please, try again' isWarning />);
      }
      return;
    }
    setCurrentStepId(STEPS[currentStepIndex + 1].id, isReplace);
  };

  const updateComponentsData = (data: any) => {
    // @ts-ignore
    setStepsData(prevData => ({ ...prevData, components: { ...(prevData?.components ?? {}), ...data } }));
  };

  const getStepDescription = (stepId: string) => {
    const stepData = stepsData[stepId] as any;
    return typeof stepData === 'string' ? stepData : stepData?.name;
  };

  const assetData = {
    modelImage: (stepsData?.modelNumber as any)?.img,
    modelNumber: (stepsData?.modelNumber as any)?.name,
    customer: (stepsData?.customer as any)?.name,
    customerId: (stepsData?.customer as any)?.id,
    serialNumber: stepsData?.serialNumber,
  };

  return (
    <div className={styles.container}>
      <Routes>
        <Route path='/' element={
          <>
            <div className={styles.steps}>
              {STEPS.map((step, index) => (
                <div key={step.id} className={`${styles.step} ${step.id === currentStepId && styles.activeStep}`}>
                  {(!!stepsData[step.id] && currentStepIndex > index) &&
                    <CheckIcon className={styles.completedStepCheckmark} />}
                  <div
                    className={(!!stepsData[step.id] && currentStepIndex > index) ? styles.completedStep : ''}
                  >
                    {step.name}
                  </div>
                  <div className={styles.stepDescription}>{getStepDescription(step.id)}</div>
                </div>
              ))}
            </div>
            <div className={styles.stepContent}>
              <div className={styles.title}>{title}</div>
              {description && <div className={styles.description}>{description}</div>}
              <div className={styles.actions}>
                <Component
                  goToNextStep={goToNextStep}
                  assetData={assetData}
                  modelData={stepsData.modelNumber}
                  componentsData={stepsData.components}
                  updateComponentsData={updateComponentsData}
                />
              </div>
            </div>
          </>
        } />
        <Route path='/board' element={<ActivateBoardPage modelData={stepsData.modelNumber} componentsData={stepsData.components} updateComponentsData={updateComponentsData} />} />
        <Route path='/label' element={<ActivateLabelPage modelData={stepsData.modelNumber} componentsData={stepsData.components} updateComponentsData={updateComponentsData} />} />
      </Routes>
      <MAFooter
        onGoBack={goToPrevStep}
        submitLabel={currentStepId === 'components' ? 'Review & Submit' : (currentStepId === 'review' ? 'Complete' : '')}
        onSubmit={goToNextStep}
      />
    </div>
  );
};
