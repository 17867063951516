import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import styles from './styles.module.css';

export const ToastContent = ({ title = '', message = '', isWarning = false }) => {
  return (
    <div className={styles.wrapper}>
      {isWarning ? <ExclamationIcon className={styles.icon} /> : <CheckIcon className={styles.icon} />}
      <div>
        <p className={styles.title}>{title}</p>
        {message && <p className={styles.message}>{message}</p>}
      </div>
    </div>
  );
};
