import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as ComponentsIcon } from '../../../../assets/icons/components.svg';
import { ReactComponent as ExclamationIcon } from '../../../../assets/icons/exclamation.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import addBoardQr from '../../../../assets/qr/add-board.png';
import addLabelQr from '../../../../assets/qr/add-label.png';
import { useScannerInput } from '../../../../hooks/use-scanner-input';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { ToastContent } from '../../../../../../components/ToastContent';
import styles from './styles.module.css';

export const ComponentsStep = ({ modelData, componentsData, updateComponentsData }: { modelData?: any; componentsData?: any, updateComponentsData: (data: any) => void }) => {
  const navigate = useNavigate();
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState<string>();
  const [itemsQrs, setItemsQrs] = useState<string[]>([]);

  const onAddBoard = () => {
    navigate('/manufacturing-activation/activate/board');
  };

  const onAddLabel = () => {
    navigate('/manufacturing-activation/activate/label');
  };

  const connectedItems = componentsData?.items ?? [];

  const supportedPartModels = modelData?.supportedPartModels ?? [];
  const supportedPartModelsAmount = supportedPartModels?.length ?? 0;

  const supportedPartModelsWithState = supportedPartModels.map((pm: any) => ({
    ...pm,
    connectedState: connectedItems.find((connectedPm: any) => connectedPm.id === pm.id),
  }));

  const supportedPartModelsWithStateSorted = useMemo(() => {
    return [...supportedPartModelsWithState].sort((a: any, b: any) => a?.type.localeCompare(b?.type));
  }, [supportedPartModelsWithState]);

  const updateQrs = async () => {
    const qrs = await Promise.all(supportedPartModelsWithStateSorted.map((_: any, index: number) => {
      return QRCode.toDataURL(`$hyve_remove-item-${index}$`, { margin: 2, width: 580 });
    }));
    setItemsQrs(qrs);
  };

  useEffect(() => {
    updateQrs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupportedPartModelsByType = (type: string) => {
    return supportedPartModels.filter((pm: any) => pm.type === type);
  };

  const supportedBoardsAmount = getSupportedPartModelsByType('Board')?.length ?? 0;
  const supportedLabelsAmount = getSupportedPartModelsByType('Label')?.length ?? 0;

  const onRemoveComponent = (componentTag: string) => {
    let updatedComponentsData = { items: [], ...(componentsData ?? {}) };
    const itemInListNumber = +componentTag.replace(/\D/g, '');

    const partModelWithState = supportedPartModelsWithStateSorted[itemInListNumber];
    if (updatedComponentsData?.items && partModelWithState?.connectedState) {
      const itemToUpdate = partModelWithState.connectedState;
      const itemToUpdateName = itemToUpdate.name;
      const itemToUpdateType = itemToUpdate.type;
      updatedComponentsData.items = updatedComponentsData.items.filter((item: any) => {
        const isItemToRemove = item.id === itemToUpdate.id && item.type === itemToUpdateType;

        return !isItemToRemove;
      });
      updatedComponentsData.name = updatedComponentsData.items?.length ? `${updatedComponentsData.items?.length} items` : undefined;
      toast(<ToastContent title={`${itemToUpdateType} removed`} message={`Board ${itemToUpdateName} is successfully removed.`} />);
      updateComponentsData(updatedComponentsData);
    }
  };

  const processComponentsInput = (input: string) => {
    if (input.startsWith('$hyve_remove-item-')) {
      return setShowRemovalConfirmation(input);
    }

    switch (input) {
      case '$hyve_add-board$':
        onAddBoard();
        break;
      case '$hyve_add-label$':
        onAddLabel();
        break;
      default:
        break;
    }
  };

  const getComponentNameByActionId = (actionId?: string) => {
    if (!actionId) {
      return '';
    }

    const itemIndex = +actionId.replace(/\D/g, '');
    return componentsData?.items?.[itemIndex]?.name;
  };

  useScannerInput(processComponentsInput);

  const showAllItemsAdded = componentsData?.items?.length === supportedPartModelsAmount;

  return (
    <div className={styles.container}>
      <ConfirmationModal
        isOpen={!!showRemovalConfirmation}
        title={getComponentNameByActionId(showRemovalConfirmation)}
        description="Are you sure you want to remove this component?"
        onConfirm={() => {
          showRemovalConfirmation && onRemoveComponent(showRemovalConfirmation);
          setShowRemovalConfirmation(undefined);
        }}
        onCancel={() => setShowRemovalConfirmation(undefined)}
      />
      <div className={styles.leftCards}>
        {showAllItemsAdded ? (
          <div className={styles.completeStateCard}>
            <CheckIcon className={styles.completeStateCardIcon} width={57} height={57} />
            <div className={styles.completeStateCardTitle}>
              All components added
            </div>
            <div className={styles.completeStateCardDescription}>
              You can proceed to the next step
            </div>
          </div>
        ) : (
          <>
            {(componentsData?.items ?? []).filter((i: any) => i.type === 'Board').length !== supportedBoardsAmount && (
              <div className={styles.card} onClick={onAddBoard}>
                <img src={addBoardQr} width={120} height={120} alt='Add board' />
                <div>
                  Add board
                </div>
              </div>
            )}
            {(componentsData?.items ?? []).filter((i: any) => i.type === 'Label').length !== supportedLabelsAmount && (
              <div className={styles.card} onClick={onAddLabel}>
                <img src={addLabelQr} width={120} height={120} alt='Add label' />
                <div>
                  Add label
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.rightCard}>
        <div className={styles.rightCardTitle}>
          Recently added
        </div>
        {supportedPartModelsWithStateSorted.map((pmWithState: any, index: number, arr: any[]) => (
          <div key={pmWithState.id + pmWithState.type} className={`${styles.item} ${pmWithState.connectedState ? styles.itemActive : ''}`}>
            {pmWithState.connectedState ? <CheckIcon width={24} height={24} /> : <ExclamationIcon />}
            {pmWithState.connectedState ? <ComponentsIcon className={styles.itemTypeIcon} /> : null}
            <div>
              <p>{pmWithState.type} {index - arr.findIndex(pm => pm.type === pmWithState.type) + 1}</p>
              <p style={{ fontSize: 16, color: '#F9F9F9' }}>{pmWithState.connectedState ? pmWithState.name : null}</p>
              <p style={{ fontSize: 16, color: '#6E718A' }}>{pmWithState.connectedState?.serialNumber ? pmWithState.connectedState.serialNumber : null}</p>
            </div>
            {pmWithState.connectedState ? (
              <div className={styles.removeBtn} onClick={() => setShowRemovalConfirmation(`$hyve_remove-item-${index}$`)}>
                <TrashIcon />
                <img src={itemsQrs[index]} alt='' />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};
